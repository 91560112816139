import React from 'react';
import Button from "../../../components/Buttons/Button";
import {useMutation} from "react-query";
import Step6Service from "../../../services/Step6Service";
import {toast} from "react-toastify";
import TextInput from "../../../components/Inputs/TextInput";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {selectMobile} from "../../../features/appSlice";
import {useForm} from "react-hook-form";

const VoucherInput= ({transactionId, refetch}) => {
    const isMobile = useSelector(selectMobile);
    const {mutate} = useMutation(Step6Service.assignVoucher(), {
        onSuccess: () => {
            toast.success("Voucher został aktywowany")
            refetch()
        },
        onError: (error) => {
            toast.error("Wprowadzony kod jest nieprawidłowy, sprawdź i wprowadź go ponownie");
        },
    })
    const {
        register,
        handleSubmit,
    } = useForm();
    const assignVoucher = ({code}) => {
        mutate({
            code: code,
            transaction_id: transactionId
        });
    }

    return (
        <Wrapper isMobile={isMobile}>
                <Container isMobile={isMobile}>
                    <StyledTextInput
                        placeholder={"Wprowadź kod vouchera lub kod promocyjny"}
                        register={register("code")}
                    />
                    <StyledButton buttonType="outline" type="submit" onClick={handleSubmit(assignVoucher)}>Aktywuj</StyledButton>
                </Container>
        </Wrapper>
    )
}

const Container = styled.div`
     display: flex;
    flex-direction: ${props => props.isMobile ? 'column' : 'row'};
    gap: 20px;
    width: 100%;
    margin-top: 1rem;
`;

const StyledButton = styled(Button)`
    flex: 1 1 25%
`;

const StyledTextInput = styled(TextInput)`
    flex: 1 1 75%
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  max-width: ${props => props.isMobile ? '100%' : '550px'}
`

export default VoucherInput;