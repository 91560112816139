import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components";
import * as yup from "yup";
import NavButton from "../../../components/Buttons/NavButton";
import Loader from "../../../components/Loader/Loader";
import {
    selectStep1Data,
    selectTransactionId,
    setStep1
} from "../../../features/transactionSlice";
import Step1Service from "../../../services/Step1Service";
import Step2Service from "../../../services/Step2Service";
import RentInput, { ErrorContainer } from "../components/RentInput";
import RentLayout from "../components/RentLayout";
import RentSubtitle from "../components/RentSubtitle";
import Person from "../../../components/Icons/components/Person";
import Businessman from "../../../components/Icons/components/Businessman";
import Building from "../../../components/Icons/components/Building";
import Tile from "../../../components/Icons/components/Tile";
import FlexBox from "../../../components/FlexBox/FlexBox";
// import { FaAngleDown } from 'react-icons/fa'
// import Separator from "../../../components/Separator/Separator";
// import Button from "../../../components/Buttons/Button";

import { theme } from '../../../theme'

const SubtitleContainer = styled.div`
    margin-bottom: 28px;
    font-size: 1.75rem;
    text-align: center;
`;
const FormContainer = styled.div`
    margin-top: 30px;
    max-width: 600px;
    margin: auto;
`;

const IconLabel = styled.label`
    display: inline-block;
    cursor: pointer;
    & > input {
        position: absolute;
        display: block;
        width: 0px;
        height: 0px;
        opacity: 0;
        top: -1000px;
        left: -1000px;
    }
`;
const StreetRow = styled.div`
    display: flex;
    gap: 16px;
    & > div:first-child {
        flex: 1;
    }
`;
const CityRow = styled.div`
    display: flex;
    gap: 16px;
    & > div:last-child {
        flex: 1;
    }
`;

const RadioContainer = styled.div`
    border: 1px solid
        ${({ theme, isError }) => (isError ? theme.danger : "transparent")};
    padding: 10px;
    margin: 10;
    display: flex;
    label > span {
        font-weight: bold;
    }
`;

const InputGroup = styled.div`
    font-size: 1.125rem;
    font-weight: bold;
`;

/*const Info = styled.div`
    font-size: 13px;
    padding: 20px;
    border: 2px solid ${props => props.theme.primary};
    border-radius: 20px;
    text-align: center;
`;*/


const Tip = styled.div`
    color: #444;
    margin-top: -10px;
    margin-bottom: 20px;
`

const NIP_ERROR =
    "Sprawdź, czy wprowadzony numer NIP jest prawidłowy. Wprowadź NIP jako ciąg liczb, bez spacji, myślników, ani innych dodatkowych znaków";

const schema = yup.object().shape({
    apartment: yup.string(),
    city: yup.string().required("Pole wymagane"),
    customerType: yup
        .string()
        .oneOf(["NATURAL_PERSON", "INDIVIDUAL_ENTREPRENEUR", "LEGAL_PERSON"])
        .required("Pole wymagane"),
    firstName: yup.string().required("Pole wymagane"),
    idCard: yup.string().required("Pole wymagane"),
    lastName: yup.string().required("Pole wymagane"),
    personalNumber: yup.string().required("Pole wymagane"),
    phone: yup.string().required("Pole wymagane"),
    postCode: yup.string().required("Pole wymagane"),
    street: yup.string().required("Pole wymagane"),
    // nip: yup
    //     .string(NIP_ERROR)
    //     .min(10, NIP_ERROR)
    //     .max(10, NIP_ERROR)
    //     .when("customerType", {
    //         is: "INDIVIDUAL_ENTREPRENEUR",
    //         then: yup.string().required("Pole wymagane"),
    //     })
    //     .when("customerType", {
    //         is: "LEGAL_PERSON",
    //         then: yup.string(NIP_ERROR).required("Pole wymagane"),
    //     })
    //     .when("customerType", {
    //         is: "NATURAL_PERSON",
    //         then: yup.string(NIP_ERROR),
    //     }),
    nip: yup
        .string("string")
        .test("nip", NIP_ERROR, (value, values) => {
            if (values.parent.customerType === "NATURAL_PERSON") {
                return true;
            } else {
                if (value.length === 10) {
                    return true;
                }
            }
            return false;
        }),
});

const LABELS = {
    nip: {
        LEGAL_PERSON: "NIP Spółki / Fundacji / Instytucji",
        INDIVIDUAL_ENTREPRENEUR: "NIP",
    },
    companyName: {
        LEGAL_PERSON: "Pełna nazwa Spółki / Fundacji / Instytucji",
        INDIVIDUAL_ENTREPRENEUR: "Pełna nazwa działalności gospodarczej",
    },
    firstName: {
        LEGAL_PERSON: "Imię osoby reprezentującej Najemcę",
        INDIVIDUAL_ENTREPRENEUR: "Imię",
        NATURAL_PERSON: "Imię",
    },
    lastName: {
        LEGAL_PERSON: "Nazwisko osoby reprezentującej Najemcę",
        INDIVIDUAL_ENTREPRENEUR: "Nazwisko",
        NATURAL_PERSON: "Nazwisko",
    },
    phone: {
        LEGAL_PERSON: "Telefon komórkowy osoby reprezentującej Najemcę",
        INDIVIDUAL_ENTREPRENEUR: "Numer telefonu",
        NATURAL_PERSON: "Numer telefonu",
    },
    personalNumber: {
        LEGAL_PERSON: "PESEL osoby reprezentującej Najemcę",
        INDIVIDUAL_ENTREPRENEUR: "PESEL",
        NATURAL_PERSON: "PESEL",
    },
    idCard: {
        LEGAL_PERSON:
            "Seria i numer dowodu osobistego osoby reprezentującej Najemcę",
        INDIVIDUAL_ENTREPRENEUR: "Numer i seria dowodu osobistego",
        NATURAL_PERSON: "Numer i seria dowodu osobistego",
    },
    address: {
        LEGAL_PERSON:
            "Adres zamieszkania osoby reprezentującej Najemcę",
        INDIVIDUAL_ENTREPRENEUR: "ADRES ZAMIESZKANIA",
        NATURAL_PERSON: "ADRES ZAMIESZKANIA",
    },
};

const DesktopContractData = ({ navProps }) => {
    const history = useHistory();

    const [step, setStep] = useState(0);
    const [isSubmit, setIsSubmit] = useState(false)


    const dispatch = useDispatch();

    const initialData = useSelector(selectStep1Data);
    const transactionId = useSelector(selectTransactionId);

    const {
        register,
        handleSubmit,
        watch,
        setError,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: initialData,
        resolver: yupResolver(schema),
    });

    const { mutateAsync, isLoading } = useMutation(
        Step1Service.saveStep1(transactionId),
        {
            onError: (e) => {
                e.response.data?.errors?.forEach(
                    ({ message, invalid_property }) => {
                        setError(invalid_property, {
                            type: "manual",
                            message,
                        });
                    }
                );
                toast.error("Wpisano błędne dane");
                setStep(0)
            },
        }
    );

    const customerType = watch("customerType");

    useEffect(() => {
        if (customerType === "NATURAL_PERSON") {
            setValue("nip", "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerType]);
    const nip = watch("nip");

    const {
        mutateAsync: checkNipMutation,
        data: nipData,
        isLoading: isNipLoading,
    } = useMutation(Step2Service.checkNip(nip), {
        onError: () => {
            setError("nip", {
                type: "manual",
                message: "Nie znaleziono numeru nip",
            });
        },
    });

    const {
        mutateAsync: saveLegalPerson,
        isLoading: isLegalPersonLoading,
    } = useMutation(Step2Service.saveLegalPerson(transactionId));
    const {
        mutateAsync: saveIndividualEntrepreneur,
        isLoading: isIndividualEntrepreneuerLoading,
    } = useMutation(Step2Service.saveIndividualEntrepreneur(transactionId));

    useEffect(() => {
        if (nip?.length === 10) {
            checkNipMutation();
        }
    }, [checkNipMutation, nip]);

    const onSubmit = async (values) => {
        const {
            apartment,
            city,
            customerType,
            firstName,
            idCard,
            lastName,
            personalNumber,
            phone,
            postCode,
            street,
        } = values;

        const fields = {
            c2: firstName,
            c3: lastName,
            c4: personalNumber,
            c8: idCard,
            c17: phone,
            c10: street,
            c11: city,
            c12: postCode,
            c36: apartment,
            // c29: 1,
            c27: { c2: customerType },
            // c39: 0,
            // c45: 0,
        };

        if (customerType === "NATURAL_PERSON") {
            await mutateAsync(fields);
            dispatch(setStep1(values));
            history.push("/rent/financial-data");
        }
        if (customerType === "INDIVIDUAL_ENTREPRENEUR") {
            await mutateAsync(fields);
            await saveIndividualEntrepreneur({
                c5: nip,
            });
            dispatch(setStep1(values));
            // history.push("/rent/financial-data");
            history.push("/rent/online-application");
        }
        if (customerType === "LEGAL_PERSON") {
            await mutateAsync(fields);
            await saveLegalPerson({
                c5: nip,
            });
            history.push("/rent/online-application");
            dispatch(setStep1(values));
        }
    };

    const showLoader =
        isLoading || isLegalPersonLoading || isIndividualEntrepreneuerLoading;

    const [customer, setCustomer] = useState('NATURAL_PERSON')

    const handleClick = (e) => {
        setCustomer(e.target.value)
    }

    const checkErrors = () => {
        if (
            errors['firstName']
            || errors['lastName']
            || errors['phone']
            || errors['personalNumber']
            || errors['idCard']
            || errors['nip']
        ) {
            setStep(1)
        }
        else if (
            errors['street']
            || errors['city']
            || errors['postCode']
            || errors['apartment']
        ) {
            setStep(2)
        }
    }

    useEffect(() => {
        checkErrors()
        // eslint-disable-next-line
    }, [errors])



    useEffect(() => {
        if (step === 2) {
            setIsSubmit(true)
        }
        else {
            setIsSubmit(false)
        }
    }, [step])


    return (
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <RentLayout {...navProps} showNav={false} showLoader={showLoader}>
                {step === 0 && (
                    <div>
                        <SubtitleContainer>
                            <RentSubtitle>
                                KTO WYNAJMUJE SPRZĘT?
                            </RentSubtitle>
                        </SubtitleContainer>
                        <RadioContainer isError={!!errors?.customerType}>
                            {/* <Controller
                                control={control}
                                name="customerType"
                                render={({ field: { onChange, value } }) => (
                                      <Radio
                                value={value}
                                onChange={(e) => onChange(e)}
                                options={[
                                    {
                                        label: "Osoba fizyczna",
                                        value: "NATURAL_PERSON",
                                    },
                                    {
                                        label:
                                            "Jednoosobowa działalność gospodarcza",
                                        value: "INDIVIDUAL_ENTREPRENEUR",
                                    },
                                    {
                                        label: "Spółka / Fundacja / Instytucja",
                                        value: "LEGAL_PERSON",
                                    },
                                ]}
                            />  */}
                            <FlexBox justifyContent='space-between' flex="1">
                                <InputGroup>
                                    <IconLabel htmlFor="NATURAL_PERSON">
                                        <Tile
                                            icon={<Person color={theme.primary} />}
                                            active={customer === 'NATURAL_PERSON'}
                                        />
                                        <input
                                            type='radio'
                                            id='NATURAL_PERSON'
                                            value='NATURAL_PERSON'
                                            {...register("customerType")}
                                            onClick={handleClick}
                                        />
                                    </IconLabel>
                                    <p>Osoba Fizyczna</p>
                                </InputGroup>
                                <InputGroup>
                                    <IconLabel htmlFor="INDIVIDUAL_ENTREPRENEUR"  >
                                        <Tile
                                            icon={<Businessman color={theme.primary} />}
                                            active={customer === 'INDIVIDUAL_ENTREPRENEUR'}
                                        />
                                        <input
                                            type='radio'
                                            id='INDIVIDUAL_ENTREPRENEUR'
                                            value='INDIVIDUAL_ENTREPRENEUR'
                                            {...register("customerType")}
                                            onClick={handleClick}
                                        />
                                    </IconLabel>
                                    <p>Jednoosobowa działalność gospodarcza</p>
                                </InputGroup>
                                <InputGroup>
                                    <IconLabel htmlFor="LEGAL_PERSON">
                                        <Tile
                                            icon={<Building color={theme.primary} />}
                                            active={customer === 'LEGAL_PERSON'}
                                        />
                                        <input
                                            type='radio'
                                            id='LEGAL_PERSON'
                                            value='LEGAL_PERSON'
                                            {...register("customerType")}
                                            onClick={handleClick}
                                        />
                                    </IconLabel>
                                    <p>Spółka / Fundacja / Instytucja</p>
                                </InputGroup>
                            </FlexBox>
                            <ErrorContainer>
                                {errors?.customerType?.message}
                            </ErrorContainer>
                        </RadioContainer>
                    </div>
                )}
                {step === 1 && (
                    <FormContainer>
                        <SubtitleContainer>
                            <RentSubtitle>DANE WNIOSKODAWCY:</RentSubtitle>
                        </SubtitleContainer>
                        {(customerType === "INDIVIDUAL_ENTREPRENEUR" ||
                            customerType === "LEGAL_PERSON") && (
                                <>
                                    <RentInput
                                        label={LABELS.nip[customerType]}
                                        name="nip"
                                        error={errors.nip}
                                        register={register}
                                    />
                                    {isNipLoading && <Loader />}
                                    {nipData?.c20 && (
                                        <RentInput
                                            label={LABELS.companyName[customerType]}
                                            value={nipData?.c20}
                                            disabled
                                        />
                                    )}
                                </>
                            )}
                        <RentInput
                            label={
                                LABELS.firstName[
                                customerType || "INDIVIDUAL_ENTREPRENEUR"
                                ]
                            }
                            register={register}
                            name="firstName"
                            error={errors["c2"] || errors.firstName}
                        />

                        <RentInput
                            label={
                                LABELS.lastName[
                                customerType || "INDIVIDUAL_ENTREPRENEUR"
                                ]
                            }
                            register={register}
                            name="lastName"
                            error={errors["c3"] || errors.lastName}
                            description={'Nazwisko dwuczłonowe wpisz z myślnikiem, czyli np. “Kowalska-Nowak”'}
                        />
                        <Tip>Nazwisko dwuczłonowe wpisz z myślnikiem, czyli np. “Kowalska-Nowak”</Tip>


                        <RentInput
                            label={
                                LABELS.phone[
                                customerType || "INDIVIDUAL_ENTREPRENEUR"
                                ]
                            }
                            description="Numer telefonu będzie potrzebny w trakcie realizacji transakcji do potwierdzenia warunków umowy oraz przy odbiorze sprzętu."
                            register={register}
                            name="phone"
                            error={errors["c17"] || errors.phone}
                        />
                        <RentInput
                            label={
                                LABELS.personalNumber[
                                customerType || "INDIVIDUAL_ENTREPRENEUR"
                                ]
                            }
                            description="Numer PESEL jest nam niezbędny do weryfikacji tożsamości Klienta wypożyczającego sprzęt."
                            register={register}
                            name="personalNumber"
                            error={errors["c4"] || errors.personalNumber}
                        />
                        <RentInput
                            label={
                                LABELS.idCard[
                                customerType || "INDIVIDUAL_ENTREPRENEUR"
                                ]
                            }
                            description="Numer dowodu osobistego jest niezbędny do identyfikacji Najemcy przy odbiorze sprzętu."
                            register={register}
                            name="idCard"
                            error={errors["c8"] || errors.idCard}
                        />
                    </FormContainer>
                )}
                {step === 2 && (
                    <FormContainer>
                        <SubtitleContainer>
                            <RentSubtitle>
                                {
                                    `${LABELS.address[customerType || "INDIVIDUAL_ENTREPRENEUR"]}:`
                                }
                            </RentSubtitle>
                        </SubtitleContainer>
                        <StreetRow>
                            <RentInput
                                label="Ulica i numer budynku"
                                register={register}
                                name="street"
                                error={errors["c10"] || errors.street}
                            />
                            <RentInput
                                label="Numer lokalu"
                                register={register}
                                name="apartment"
                                error={errors["c36"] || errors.apartment}
                            />
                        </StreetRow>

                        <CityRow>
                            <RentInput
                                label="Kod pocztowy"
                                register={register}
                                name="postCode"
                                error={errors["c12"] || errors.postCode}
                            />
                            <RentInput
                                label="Miejscowość"
                                register={register}
                                name="city"
                                error={errors["c11"] || errors.city}
                            />
                        </CityRow>
                    </FormContainer>
                )}
                {/*     {step === 3 && (
                    <FormContainer>
                        <SubtitleContainer>
                            <RentSubtitle>{step + 1}. Odbiór osobisty sprzętu</RentSubtitle>
                        </SubtitleContainer>
                        <FlexBox justifyContent="space-between" fontSize="18px">
                            <FlexBox flexDirection="column">
                                <p><strong>Skład muzyczny</strong></p>
                                <p>31-066 Kraków</p>
                                <p>ul. Skawińska 14</p>
                                <p>tel. 12 346 18 42</p>
                            </FlexBox>
                            <FlexBox alignItems="center">
                                0 zł
                                <FaAngleDown />
                            </FlexBox>
                        </FlexBox>
                        <Separator margin="20px 0 40px 0" color="#CECECE" />
                        <Info>
                            Nim wybierzesz się do punktu partnera - umów się telefonicznie na odbiór sprzętu.<br />
                            Udając się po odbiór zabierz ze sobą telefon, którego numer podany został w procesie rejestracji.
                            <br /><br />

                        </Info>

                    </FormContainer>
                )} */}
                {/* <FormContainer>
                    <SubtitleContainer>
                        <RentSubtitle>DANE WNIOSKODAWCY:</RentSubtitle>
                    </SubtitleContainer>
                    {(customerType === "INDIVIDUAL_ENTREPRENEUR" ||
                        customerType === "LEGAL_PERSON") && (
                        <>
                            <RentInput
                                label={LABELS.nip[customerType]}
                                name="nip"
                                error={errors.nip}
                                register={register}
                            />
                            {isNipLoading && <Loader />}
                            {nipData?.c20 && (
                                <RentInput
                                    label={LABELS.companyName[customerType]}
                                    value={nipData?.c20}
                                    disabled
                                />
                            )}
                        </>
                    )}
                    <RentInput
                        label={
                            LABELS.firstName[
                                customerType || "INDIVIDUAL_ENTREPRENEUR"
                            ]
                        }
                        register={register}
                        name="firstName"
                        error={errors["c2"] || errors.firstName}
                    />

                    <RentInput
                        label={
                            LABELS.lastName[
                                customerType || "INDIVIDUAL_ENTREPRENEUR"
                            ]
                        }
                        register={register}
                        name="lastName"
                        error={errors["c3"] || errors.lastName}
                    />

                    <RentInput
                        label={
                            LABELS.phone[
                                customerType || "INDIVIDUAL_ENTREPRENEUR"
                            ]
                        }
                        description="Lorem"
                        register={register}
                        name="phone"
                        error={errors["c17"] || errors.phone}
                    />
                    <RentInput
                        label={
                            LABELS.personalNumber[
                                customerType || "INDIVIDUAL_ENTREPRENEUR"
                            ]
                        }
                        description="Lorem"
                        register={register}
                        name="personalNumber"
                        error={errors["c4"] || errors.personalNumber}
                    />
                    <RentInput
                        label={
                            LABELS.idCard[
                                customerType || "INDIVIDUAL_ENTREPRENEUR"
                            ]
                        }
                        description="Lorem"
                        register={register}
                        name="idCard"
                        error={errors["c8"] || errors.idCard}
                    />
                </FormContainer>

                <FormContainer>
                    <SubtitleContainer>
                        <RentSubtitle>
                            {
                                LABELS.address[
                                    customerType || "INDIVIDUAL_ENTREPRENEUR"
                                ]
                            }
                            :
                        </RentSubtitle>
                    </SubtitleContainer>
                    <RentInput
                        label="Ulica i numer budynku"
                        register={register}
                        name="street"
                        error={errors["c10"] || errors.street}
                    />
                    <RentInput
                        label="Numer lokalu"
                        register={register}
                        name="apartment"
                        error={errors["c36"] || errors.apartment}
                    />
                    <RentInput
                        label="Kod pocztowy"
                        register={register}
                        name="postCode"
                        error={errors["c12"] || errors.postCode}
                    />
                    <RentInput
                        label="Miejscowość"
                        register={register}
                        name="city"
                        error={errors["c11"] || errors.city}
                    />
                </FormContainer> */}
                <FormContainer>
                    <NavButton
                        backText='Twój koszyk najmu '
                        backLink={step === 0 && "/basket"}
                        onBackClicked={() =>
                            setStep((step) => Math.max(step - 1, 0))
                        }
                        onNextClicked={() =>
                            setStep((step) => Math.min(step + 1, 2))}
                        submit={isSubmit}
                        disabled={showLoader}
                    />

                </FormContainer>
            </RentLayout>
        </form >
    );
};

export default DesktopContractData;

