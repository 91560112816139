import {useEffect, useMemo, useState} from "react";
import {useMutation, useQuery} from "react-query";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import styled from "styled-components";
import Button from "../../components/Buttons/Button";
import Container from "../../components/Container/Container";
import BackArrowIcon from "../../components/Icons/components/BackArrowIcon";
import Layout from "../../components/Layout/Layout";
import Loader from "../../components/Loader/Loader";
import { selectTransactionId } from "../../features/transactionSlice";
import Step6Service from "../../services/Step6Service";
import { clearData } from "../../utils/functions";
import { selectMobile } from '../../features/appSlice'
import {toast} from "react-toastify";

const Title = styled.h3`
    font-size: ${props => props.isMobile ? '20px' : '28px'};
    line-height: 26px;
    padding-top: ${props => props.isMobile ? '38px' : '54px'};
    padding-bottom: ${props => props.isMobile ? '10px' : '16px'};
`;
const Text = styled.div`
    font-size: 17px;
    line-height: 18px;
`;
const BottomText = styled.div`
    margin-top: 1em;
`;

const Footer = styled.footer`
    padding-bottom: 44px;
    margin-top: 20px;
    
`;
const Address = styled.div`
    line-height: 18px;
    text-align: center;
    a {
        display: block;
        margin-bottom: 1em;
        color: ${({ theme }) => theme.white};
    }
`;
// const LinkContainer = styled.div`
//     display: flex;
//     align-items: center;
//     margin: 50px 0;
//     svg {
//         color: ${({ theme }) => theme.primary};
//         margin-right: 10px;
//     }
//     & > div {
//         text-decoration: underline;
//     }
// `;

const Box = styled.div`
    max-width: ${props => !props.isMobile && '424px'};
    background: ${({ theme }) => theme.primaryGradient};
    border-radius: 50px;
    color: white;
    font-size: 18px;
    line-height: 18px;
    padding: 28px 0;
    margin: ${props => props.isMobile ? '20px 0 30px 0' : '40px auto 40px auto'};
    header {
        padding-bottom: 14px;
        margin-bottom: 24px;
        border-bottom: 1px solid white;
    }
    p {
        line-height: 24px;
    }
`;

const Wrapper = styled.div`
    max-width: ${props => props.isMobile ? '100%' : '580px'};
    text-align: center;
    margin: auto;
`;

const BackgroundColor = styled.div`
    background-color: ${props => !props.isMobile && props.theme.white};
`;
const Finished = ({ type = "address", street, phone, postcode, city }) => {

    const { session_id } = useParams();
    const isMobile = useSelector(selectMobile);
    const transactionId = useSelector(selectTransactionId);
    const location = useLocation();
    const [unauthorized, setUnauthorized] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(-2);
    const [paymentStatusAttempts, setPaymentStatusAttempts] = useState(0);

    const returnUrl = useMemo(() => {
        const searchQuery = new URLSearchParams(location.search);
        return searchQuery.get("return_url") || 'https://vibe.pl';
    }, [location.search]);

    const { data, isLoading: isGettingData } = useQuery(
        "payment-summary-finished",
        Step6Service.getData(transactionId),
        {
            onSuccess: () => {
                setUnauthorized(false);
                checkPayment();
            },
            onError: () => {
                setUnauthorized(true);
                clearData();
            },
        }
    );


    const {
        mutateAsync: checkPayment,
        isLoading: isCheckingPayment,
    } = useMutation(Step6Service.checkPaymentStatus(session_id), {
        onSuccess: ({status}) => {
            setPaymentStatus(status);
            setPaymentStatusAttempts( prev => prev+1);
            if(status === 0 || status === -1){
                toast.error("Płatność nie powiodła się");
                clearData();
            }
            if(status === 2){
                clearData();
            }
        },
        onError: () => {
            toast.error("Płatność nie powiodła się");
            clearData();
        },
    });

    useEffect(() => {
        if(paymentStatus === 1){
            if(paymentStatusAttempts < 10){
                setTimeout(() => {
                    checkPayment();
                },10000);
            }else{
                setPaymentStatus(0);
                toast.error("Płatność nie powiodła się");
                clearData();
            }
        }
        // eslint-disable-next-line
    }, [paymentStatusAttempts]);


    const goto_shop = async () => {
        window.location.assign(returnUrl);
    };

    if (unauthorized) {
        window.location.href= '/link-inactive';
    }
    if (isGettingData || isCheckingPayment || paymentStatus === 1 || paymentStatus === -2) {
        return <Loader fixed />;
    }
    if (paymentStatus === 2){

        return (
            <Layout showBasket={false}>
                <Container>
                    <BackgroundColor isMobile={isMobile}>
                        <Wrapper isMobile={isMobile}>
                            <Container>
                                <Title isMobile={isMobile}>Gratulacje!</Title>
                                <Text>
                                    <strong>
                                        {type === "UPS" &&
                                            "Twoje zamówienie jest gotowe do wysyłki."}
                                        {type === "address" &&
                                            "Możesz już umówić się na odbiór sprzętu"}
                                    </strong>
                                </Text>

                            </Container>
                            {type === "UPS" && (
                                <Box isMobile={isMobile}>
                                    <Container>
                                        <header>Twój sprzęt dostarczy kurier {type}</header>
                                        <div>
                                            <p>
                                                Informację o dacie dostawy otrzymasz
                                                niezwłocznie po zarejestrowaniu przesyłki
                                                przez kuriera.
                                            </p>
                                        </div>
                                    </Container>
                                </Box>
                            )}
                            {type === "address" && (
                                <Box isMobile={isMobile}>
                                    <Container>
                                        <header>{data?.shop_name}</header>
                                        <Address>
                                            <div>
                                                <a href={`tel:${data?.shop_phone || "-"}`}>
                                                    tel. {data?.shop_phone || "-"}
                                                </a>
                                            </div>

                                    <div>
                                        {data?.shop_code} {data?.shop_city}<br/>
                                        ul. {data?.shop_street}
                                    </div>
                                </Address>
                            </Container>
                        </Box>
                    )}
                    <Text>
                        <strong> W momencie odbioru sprzętu na numer telefonu podany
                            w trakcie rejestracji wyślemy kod autoryzacyjny.</strong>
                        <BottomText>
                            Jeśli życzysz sobie wysyłkę kurierską, skontaktuj się z nami telefonicznie lub mailowo
                        </BottomText>
                    </Text>
                    {/*
                    <LinkContainer>
                        <RefreshIcon />
                        <div>Wyślij kod ponownie</div>
                    </LinkContainer> */}
                    {returnUrl && (
                        <Footer>
                            <Button
                                className={'mt-3'}
                                onClick={goto_shop}
                                LeftIcon={<BackArrowIcon />}
                            >
                                Powrót do sklepu
                            </Button>
                        </Footer>
                    )}
                        </Wrapper>
                    </BackgroundColor>
                </Container>
                {
                 data &&   <div id="trustedShopsCheckout" style={{display: "none"}}>
                        <span id="tsCheckoutOrderNr">{data.agreement_number}</span>
                        <span id="tsCheckoutBuyerEmail">{data.email}</span>
                        <span id="tsCheckoutOrderAmount">{data.total_gross_price_raw}</span>
                        <span id="tsCheckoutOrderCurrency">PLN</span>
                        <span id="tsCheckoutOrderPaymentType">PRZELEWY 24</span>
                        <span id="tsCheckoutOrderEstDeliveryDate">{data.date}</span></div>
                }
            </Layout>
        )
    }else{
        return(
            <Layout showBasket={false}>
                <Container>
                    <Title isMobile={isMobile}>Płatność nie powiodła się</Title>
                    <Text>
                        <strong>
                            Proszę opłacić ponownie z linku przesłanego na Państwa adres e-mail
                        </strong>
                    </Text>
                </Container>
            </Layout>
        )
    }
};

export default Finished;
