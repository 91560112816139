// import {useSelector} from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
// import {selectMobile} from "../../features/appSlice";
import Container from "../Container/Container";
import logo from "../Header/logo3.svg";
import FacebookIcon from "../Icons/socialsIcons/FacebookIcon";
import LinkedinIcon from "../Icons/socialsIcons/LinkedinIcon";
import { MOBILE_WIDTH } from "../../variables";

const Root = styled.footer`
  margin-top: 70px;
  border-top: 1px solid #cecece;

  img {
    width: 180px;
  }

  strong {
    color: #22262a;
    font-size: 1.2857142857142858em;
    line-height: 28px;
  }
`;

const TopHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
  flex-wrap: wrap;
  > div {

    margin-bottom: 1rem;
  }
`;
const BottomHeader = styled.div`
  margin-top: 38px;
  border-top: 1px solid #cecece;
`;
const BottomContainer = styled.div`
  display: flex;
  min-height: 70px;
  align-items: center;
  font-size: 0.8571428571428571em;
  line-height: 24px;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    flex-direction: column-reverse;
  }
`;
const BottomLinksContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  gap: 40px;
  margin-left: 30px;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    margin-top: 1em;
    line-height: 15px;
    gap: 10px;
    flex-direction: column;
    text-align: center;
    margin-bottom: 1em;
  }
  a {
    color: #c6c6c6;
  }
`;

const Details = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  font-size: 0.8571428571428571em;
  line-height: 24px;
  font-weight: 100;
  > div:not(:last-child) {
    margin-right: 100px;
  }

  @media screen and (max-width: 960px) {
    > div:not(:last-child) {
      margin-right: 60px;
    }
  }
`;

const IconsContainer = styled.div`
  display: flex;
  margin: -10px;
  margin-top: 20px;

  a {
    padding: 10px;
  }
`;

const DesktopFooter = () => {
  // const isMobile = useSelector(selectMobile);
  // if (isMobile) return null;
  return (
    <Root>
      <Container>
        <TopHeader>
          <div>
            <img src={logo} alt="logo" />
          </div>
          <div>
            <strong>Kontakt z nami</strong>
            <Details>
              <div>
                <div>Univibe Sp. z o.o.,</div>
                <div>Sarmacka 1, 02-972 Warszawa</div>
                <div>Tel. <a href="tel:510510092">510 510 092</a></div>
                <div>Email: <a href="mailto:info@vibe.pl">info@vibe.pl</a></div>
              </div>
              <div>
                <div>KRS 0000853684</div>
                <div>REGON 386795807</div>
                <div>NIP 9512505831</div>
              </div>
            </Details>
          </div>
          <div>
            <strong>Znajdź nas</strong>
            <IconsContainer>
              <Link to="//www.facebook.com/profile.php?id=100027268766958" target="_blank">
                <FacebookIcon fontSize='30px' />
              </Link>
              <Link to="//www.linkedin.com/company/gear4rent/?viewAsMember=true" target="_blank">
                <LinkedinIcon fontSize='30px' />
              </Link>
            </IconsContainer>
          </div>
        </TopHeader>
      </Container>

      <BottomHeader>
        <Container>
          <BottomContainer>
            <div>©2023 by Univibe Sp. z o.o.</div>
            <BottomLinksContainer>
              <Link
                to="//vibe.pl/wp-content/uploads/Regulamin-swiadczenia-uslug-UniVibe-droga-elektroniczna.pdf"
                target="_blank"
              >
                Regulamin świadczenia usług
              </Link>
              <Link to="//vibe.pl/wp-content/uploads/Polityka-Cookies-UniVibe.pdf" target="_blank">
                Polityka Cookies
              </Link>
              <Link
                to="//vibe.pl/wp-content/uploads/Polityka-Prywatnosci-UniVibe.pdf"
                target="_blank"
              >
                Polityka prywatności
              </Link>
              <Link
                to="//vibe.pl/wp-content/uploads/OWN-VIBE.pdf"
                target="_blank"
              >
                Ogólne Warunki Najmu
              </Link>
              <Link
                to="//vibe.pl/opis-procesu-wynajmu/"
                target="_blank"
              >
                Opis procesu wynajmu
              </Link>
              <Link
                to="//vibe.pl/faq/"
                target="_blank"
              >
                FAQ
              </Link>
            </BottomLinksContainer>
          </BottomContainer>
        </Container>
      </BottomHeader>
    </Root>
  );
};

export default DesktopFooter;
