import {useEffect, useMemo, useState} from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import styled from "styled-components";
import Button from "../../components/Buttons/Button";
import Container from "../../components/Container/Container";
import BackArrowIcon from "../../components/Icons/components/BackArrowIcon";
import Layout from "../../components/Layout/Layout";
import { clearData } from "../../utils/functions";
import { selectMobile } from '../../features/appSlice'
import {toast} from "react-toastify";
import {useMutation} from "react-query";
import Step6Service from "../../services/Step6Service";
import Loader from "../../components/Loader/Loader";

const Title = styled.h3`
    font-size: ${props => props.isMobile ? '20px' : '28px'};
    line-height: 26px;
    padding-top: ${props => props.isMobile ? '38px' : '54px'};
    padding-bottom: ${props => props.isMobile ? '10px' : '16px'};
`;
const Text = styled.div`
    font-size: 17px;
    line-height: 18px;
`;
// const BottomText = styled.div`
//     margin-top: 1em;
// `;

const Footer = styled.footer`
    padding-bottom: 44px;
    margin-top: 20px;
    
`;

const Wrapper = styled.div`
    max-width: ${props => props.isMobile ? '100%' : '580px'};
    text-align: center;
    margin: auto;
`;

const BackgroundColor = styled.div`
    background-color: ${props => !props.isMobile && props.theme.white};
`;
const FinishedRecurring = ({ type = "address", street, phone, postcode, city }) => {

    const { session_id } = useParams();
    const isMobile = useSelector(selectMobile)
    const location = useLocation();

    const [paymentStatus, setPaymentStatus] = useState(-2);
    const [paymentStatusAttempts, setPaymentStatusAttempts] = useState(0);

    const returnUrl = useMemo(() => {
        const searchQuery = new URLSearchParams(location.search);
        return searchQuery.get("return_url") || 'https://vibe.pl';
    }, [location.search]);


    const {
        mutateAsync: checkPayment,
        isLoading: isCheckingPayment,
    } = useMutation(Step6Service.checkPaymentStatus(session_id), {
        onSuccess: ({status}) => {
            setPaymentStatus(status);
            setPaymentStatusAttempts( prev => prev+1);
            if(status === 0 || status === -1){
                toast.error("Płatność nie powiodła się");
                clearData();
            }
            if(status === 2){
                clearData();
            }
        },
        onError: () => {
            toast.error("Płatność nie powiodła się");
            clearData();
        },
    });


    const goto_shop = async () => {
        window.location.assign(returnUrl);
    };

    useEffect(() => {
        checkPayment();
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        if(paymentStatus === 1){
            if(paymentStatusAttempts < 10){
                setTimeout(() => {
                    checkPayment();
                },10000);
            }else{
                setPaymentStatus(0);
                toast.error("Płatność nie powiodła się");
                clearData();
            }
        }
        // eslint-disable-next-line
    }, [paymentStatusAttempts]);


    if ( isCheckingPayment || paymentStatus === 1 || paymentStatus === -2) {
        return <Loader fixed />;
    }

    if (paymentStatus === 2) {

        return (
            <Layout showBasket={false}>
                <Container>
                    <BackgroundColor isMobile={isMobile}>
                        <Wrapper isMobile={isMobile}>
                            <Container>
                                <Title isMobile={isMobile}>Gratulacje!</Title>
                                <Text>
                                    <strong>
                                        Rata została opłacona
                                    </strong>
                                </Text>
                            </Container>
                            <Text>
                                <strong>Na Twój adres email wysłaliśmy szczegóły płatności</strong>
                            </Text>
                            {/*
                <LinkContainer>
                    <RefreshIcon />
                    <div>Wyślij kod ponownie</div>
                </LinkContainer> */}
                            {returnUrl && (
                                <Footer>
                                    <Button
                                        className={'mt-3'}
                                        onClick={goto_shop}
                                        LeftIcon={<BackArrowIcon/>}
                                    >
                                        Powrót do sklepu
                                    </Button>
                                </Footer>
                            )}
                        </Wrapper>
                    </BackgroundColor>
                </Container>
            </Layout>
        );
    }else{
        return(
            <Layout showBasket={false}>
                <Container>
                    <Title isMobile={isMobile}>Płatność nie powiodła się</Title>
                    <Text>
                        <strong>
                            Proszę opłacić ponownie z linku przesłanego na Państwa adres e-mail
                        </strong>
                    </Text>
                </Container>
            </Layout>
        )
    }
};

export default FinishedRecurring;
